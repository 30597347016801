import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  test: {
    translate: "Translate Portuguese",
    missing: "Only Portuguese option available",
  },
};

export default ptBR;
