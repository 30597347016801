import { BrowserRouter, Route, Routes } from "react-router-dom";

const BASENAME = "";
const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${BASENAME}/`} element={null} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
