import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  test: {
    translate: "Translate English",
    missing: "Only English option available",
  },
};

export default enUS;
