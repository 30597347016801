import {
  OptimizelyWrapper,
  UserConfigWrapper,
} from "@bees-grow-shared/business-components";
import { ThemeProvider } from "@bees-grow-shared/theme";

import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";
import { IntlProvider } from "./i18n";
import Router from "./Router";

export default function App(props: Readonly<EnvConfig>) {
  return (
    <ThemeProvider>
      <UserConfigWrapper>
        <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
          <EnvProvider env={props}>
            <IntlProvider>
              <h1 style={{ paddingLeft: "200px" }}>Leverage MFE</h1>
              <Router />
            </IntlProvider>
          </EnvProvider>
        </OptimizelyWrapper>
      </UserConfigWrapper>
    </ThemeProvider>
  );
}
