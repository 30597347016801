import MessageMap from "../i18n.d";

const es419: MessageMap = {
  test: {
    translate: "Translate Spanish",
    missing: "Only Spanish option available",
  },
};

export default es419;
